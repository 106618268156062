<script>
import useReportUser from '@/hooks/useReportUser';
import { ref } from 'vue';
import { string } from 'yup';
import { useField, useForm } from 'vee-validate';
import reportApi from '@/apis/reportApi';
import { globalState } from '@/stores/global';
import { assert, showError } from '@/services/utils';
import { queryClient } from '@/main';
import { postQuery } from '@/hooks/query';

const CONTENT_MAXLENGTH = 200;

export default {
	setup() {
		const reportUserModal = useReportUser();
		const form = useForm({
			validationSchema: {
				report_content: string()
					.max(CONTENT_MAXLENGTH)
					.required('required')
			},
			initialValues: { report_content: '' }
		});

		useField('report_content');
		const isSubmitting = ref(false);
		const toggleReportAction = async () => {
			if (!form.meta.value.valid || isSubmitting.value) return;
			if (window.confirm('本当に通報してよろしいですか？') === false) {
				return;
			}
			try {
				const { data } = reportUserModal.state;
				assert(globalState.user.id);
				assert(data);
				isSubmitting.value = true;
				let reportData;
				if (data.report_type === 1) {
					reportData = {
						report_type: data.report_type,
						post_user_id: data.post_user_id,
						user_id: globalState.user.id,
						report_content: form.values.report_content,
						post: data.post,
						puzzle_id: data.puzzle_id,
						puzzle_index: data.puzzle_index,
						piece_id: data.piece_id
					};
				}
				if (data.report_type === 2) {
					reportData = {
						report_type: data.report_type,
						post_user_id: data.post_user_id,
						user_id: globalState.user.id,
						report_content: form.values.report_content,
						comment: data.comment,
						puzzle_id: data.puzzle_id,
						puzzle_index: data.puzzle_index,
						piece_id: data.piece_id
					};
				}
				assert(reportData);
				await reportApi.createReport(reportData);
				if (data.report_type === 1) {
					queryClient.setQueryData(postQuery.usePost(data.post.docId), oldData => ({
						...oldData,
						count_reports: (oldData.count_reports || 0) + 1
					}));
				}
				if (data.report_type === 2) {
					queryClient.refetchQueries('comments');
				}
			} catch (error) {
				showError();
			} finally {
				isSubmitting.value = false;
				reportUserModal.closeModal();
			}
		};
		return { reportUserModal, form, toggleReportAction, CONTENT_MAXLENGTH };
	}
};
</script>

<template>
	<teleport to="body">
		<div class="fixed top-0 left-0 right-0 w-full h-full bg-gray-500 bg-opacity-50 overlay z-50" style="z-index: 999;">
			<div class="p-4 w-full h-full flex items-center justify-center">
				<div class="flex flex-col w-full h-auto overflow-hidden lg:p-10 p-4 mx-auto bg-white border-4 border-gray-800 lg:w-8/12 rounded-5xl">
					<div class="flex flex-row items-center mb-10">
						<button type="button" class="lg:w-6 lg:h-6 mr-4 w-4 h-4 focus:outline-none" @click="reportUserModal.closeModal()">
							<img src="@/assets/images/close-black-icon.png" class="w-full" alt="close icon" />
						</button>
						<h3 class="lg:text-2xl text-xl font-bold">絵を通報する</h3>
					</div>
					<div class="flex flex-col lg:px-10 mb-6">
						<label class="block mb-3 lg:text-xl text-base font-bold"> 通報内容 </label>
						<textarea v-model="form.values.report_content" class="h-56 px-3 py-2 mb-2 border-2 border-gray-800 focus:outline-none resize-none"></textarea>
						<div
							:class="{
								'text-red-500': form.values.report_content.length > CONTENT_MAXLENGTH || form.values.report_content.length === 0
							}"
						>
							{{ form.values.report_content.length }}/{{ CONTENT_MAXLENGTH }}
						</div>
						<p class="my-6">
							◆注意書き <br />
							：通報した方の情報を通報された方へ通知は致しません。 <br />
							：通報内容は運営側でのみ確認致します。 <br />
							：3回通報された場合、自動的に非公開となります。 <br />
							：通報内容によっては、運営側よりメールにて注意・改善を促します。
						</p>
						<p class="lg:text-xl text-base font-bold text-center">
							上記の内容でこの絵を通報しますか?
						</p>
					</div>
					<div class="flex flex-row justify-center">
						<button
							type="button"
							class="flex flex-row items-center justify-center py-2 px-2 font-bold text-gray-400 border-2 border-gray-400 rounded-full focus:outline-none lg:w-198px w-130px"
							@click="toggleReportAction"
						>
							<img src="@/assets/images/flag-gray-icon.png" alt="report flag" />
							<span class="items-center ml-3 lg:text-xl text-xs font-bold">通報する</span>
						</button>
						<button
							type="button"
							class="flex flex-row items-center justify-center py-2 lg:ml-8 ml-5 px-2 font-bold text-gray-400 border-2 border-gray-400 rounded-full focus:outline-none lg:w-198px w-130px"
							@click="reportUserModal.closeModal()"
						>
							<span class="lg:text-xl text-xs font-bold">キャンセルする</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>
