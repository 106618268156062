import { reactive, readonly } from 'vue';
import { REPORT_TYPE } from '@/constants';

const state = reactive({
	showingModal: false,
	/** @type {t.Param.CreatePostParam | undefined} */
	data: undefined
});

export default function useReportUser() {
	const closeModal = () => {
		state.showingModal = false;
		state.data = undefined;
	};

	/** @param {typeof state['data'] } data */
	const openModal = data => {
		state.showingModal = true;
		state.data = data;
	};
	return {
		state: readonly(state),
		openModal,
		closeModal
	};
}
