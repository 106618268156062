/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import firebase from 'firebase/app';
import 'firebase/firestore';
import { keyBy, pickBy } from 'lodash-es';
import { REPORT } from '@/constants';
import { convertDocument } from './utils';
import userApi from './userApi';

const { FieldValue } = firebase.firestore;
/** @type {firebase.firestore.CollectionReference<t.Comment>} */
export const commentCol = (firebase.firestore().collection('d_comment'));
export default {
	getCol() {
		return commentCol;
	},

	/** @param {Pick<t.Comment,'post_id'>} param */
	async getCommentByPostId({ post_id }) {
		const result = await commentCol
			.where('post_id', '==', post_id)
			.where('undisplay_flag', '==', 0)
			.where('delete_flag', '==', 0)
			.get();
		const data = result.docs.map(convertDocument).sort((a, b) => {
			// asc
			return (a.created_at?.seconds || 0) - (b.created_at?.seconds || 0);
		});
		return keyBy(data, c => c.docId);
	},

	/** @param {Pick<t.Comment,'comment'|'user_id'|'post_id'|'reply_comment_id'> & Pick<t.Post,'puzzle_index' |'piece_id'| 'puzzle_id'>} param */
	async create({ comment, user_id, post_id, reply_comment_id = null, puzzle_index, piece_id, puzzle_id }) {
		return commentCol.add({
			comment,
			post_id,
			user_id,
			puzzle_id,
			puzzle_index,
			piece_id,
			reply_comment_id,
			delete_flag: 0,
			undisplay_flag: 0,
			// @ts-ignore
			created_at: FieldValue.serverTimestamp(),
			updated_at: null
		});
	},

	/** @param {t.Comment['post_id']} postId */
	async getByPostId(postId) {
		const [comments, accountLockUsers] = await Promise.all([this.getCommentByPostId({ post_id: postId }), userApi.getAccountLockUsers()]);
		Object.values(comments).forEach(com => {
			if (accountLockUsers[com.user_id]) delete comments[com.docId];
		});

		const commentUsers = await userApi.getByChunkingIds({
			// @ts-ignore
			ids: [...new Set(Object.values(comments).map(com => com.user_id))]
		});

		/** @type {{ [key: string]: (t.Comment & { user: t.User, replyComments: (t.Comment & { user: t.User })[] }) }} */
		const commentsResult = (comments);
		// put replyComments comment into postComments, put user data to comment
		Object.values(commentsResult).forEach(comment => {
			comment.replyComments = comment.replyComments || [];

			comment.user = commentUsers[comment.user_id];

			if (comment.reply_comment_id) {
				const postComment = commentsResult[comment.reply_comment_id];
				if (!postComment) return;

				if ((comment.count_reports || 0) < REPORT.MAX_REPORT) {
					postComment.replyComments.push(comment);
				}
			}
		});

		return pickBy(
			commentsResult,
			// c => c.reply_comment_id === null
			c => c.reply_comment_id === null && (c.count_reports || 0) < REPORT.MAX_REPORT
		);
	}
};
