/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import firebase from 'firebase/app';
import 'firebase/firestore';
import { convertDocument, getLikeDoc, getFieldValue, likeCol, postCol, db, favoriteCol } from './utils';

export default {
	getAllByUser: async ({ user_id, delete_flag = 0 }) => {
		const result = await likeCol
			.where('user_id', '==', user_id)
			.where('delete_flag', '==', delete_flag)
			.get();
		return result.docs.map(convertDocument);
	},

	getAllByPost: async ({ post_id, delete_flag = 0 }) => {
		const likeResult = await likeCol
			.where('post_id', '==', post_id)
			.where('delete_flag', '==', delete_flag)
			.get();
		return likeResult.docs.map(convertDocument);
	},

	/** @param {Pick<t.Like,'user_id'|'post_id'>} */
	getByPost: async ({ post_id, user_id }, options = { includeDeleteFlag: true }) => {
		const likeDocId = getLikeDoc({ post_id, user_id });
		const likeRef = await likeCol.doc(likeDocId).get();

		if (likeRef.exists === false) {
			return null;
		}
		const data = convertDocument(likeRef);
		if (options?.includeDeleteFlag === true && data?.delete_flag === 1) {
			return null;
		}
		return data;
	},
	/** @param {Pick<t.Like,'user_id'|'post_id'>} */
	toggleLikePost: async ({ post_id, user_id }) => {
		let likeCount = 0;
		await db.runTransaction(async tran => {
			const likeDocId = getLikeDoc({ post_id, user_id });
			const favoriteDocId = likeDocId;

			const like = convertDocument(await tran.get(likeCol.doc(likeDocId)));
			const nextLikeCount = like?.like_count ? like.like_count + 1 : 1;
			likeCount = nextLikeCount;
			if (!like) {
				tran.set(likeCol.doc(likeDocId), {
					like_count: nextLikeCount,
					post_id,
					user_id,
					updated_at: null,
					// @ts-ignore
					created_at: getFieldValue().serverTimestamp(),
					delete_flag: 0,
					undisplay_flag: 0
				});
			} else {
				tran.update(likeCol.doc(likeDocId), {
					like_count: nextLikeCount,
					updated_at: getFieldValue().serverTimestamp()
				});
			}
			tran.update(postCol.doc(post_id), {
				like_count: getFieldValue().increment(1),
				updated_at: getFieldValue().serverTimestamp()
			});
			tran.set(
				favoriteCol.doc(favoriteDocId),
				{
					like_count: nextLikeCount,
					// @ts-ignore
					updated_at: getFieldValue().serverTimestamp()
				},
				{ merge: true }
			);
		});
		return likeCount;
	},

	/** @param {Pick<t.Like,'user_id'>} param */
	countLikesByUser: async ({ user_id }) => {
		const result = await likeCol
			.where('user_id', '==', user_id)
			.where('delete_flag', '==', 0)
			.get();

		const count = result.docs.reduce((cur, doc) => {
			cur += Number(doc.data().like_count);
			return cur;
		}, 0);
		return count;
	},

	async countLikeToPost(/** @type {t.Post['docId']} */ post_id) {
		const likes = await this.getAllByPost({ post_id });
		return likes.reduce((acc, cur) => {
			acc += Number(cur.like_count) || 0;
			return acc;
		}, 0);
	}
};
