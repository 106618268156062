<script>
/* eslint-disable no-param-reassign */
/* eslint-disable vue/valid-v-model */
/* eslint-disable global-require */
import router from '@/router';
import { globalState } from '@/stores/global';
// @ts-ignore
// @ts-ignore
import { computed, onMounted, reactive, watchEffect } from 'vue';
import postApi from '@/apis/postApi';
import likeApi from '@/apis/likeApi';
import userApi from '@/apis/userApi';
import commentApi from '@/apis/commentApi';
import notificationApi from '@/apis/notificationApi';
import { fDefined, fString, formatFbDate, getAvatarImg, showError, assert, f } from '@/services/utils';
import followApi from '@/apis/followApi';
import favoriteApi from '@/apis/favoriteApi';
import { sendNoti } from '@/apis/workerApi';
import ReportUser from '@/components/reports/ReportUser.vue';
import ExpandableImage from '@/components/UIs/ExpandableImage.vue';
// @ts-ignore
// @ts-ignore
import { REPORT, REPORT_TYPE, NOTIFICATION_TYPE } from '@/constants';
import Pagination from '@/components/UIs/Pagination.vue';
import { throttle } from 'lodash-es';
import { useComments, useFollowingUser, useNgWords, useNumbersOfLikesByUser, usePagination, usePost, useUser, useUserFavoritePost } from '@/hooks/query';
import { queryClient } from '@/main';
import useReportUser from '@/hooks/useReportUser';
import dayjs from 'dayjs';

const COMMENTS = {
	PER_PAGE: 10
};

export default {
	// eslint-disable-next-line vue/no-unused-components
	components: { ReportUser, ExpandableImage, Pagination },
	setup() {
		const globalUserId = f(globalState.user.id);
		/** @type {t.Post['docId']} */
		const postId = fString(router.currentRoute.value.params.id);
		const state = reactive({
			postComment: {
				value: '',
				error: ''
			},
			/** @type {Record<string,{ value: string, error: string, showing: boolean }>} */
			replyComment: {},
			isFirstFetchPostFinished: true,
			likeCount: 0
		});
		const { data: post, status: postStatus } = usePost(postId);
		const commentsQuery = useComments(postId);
		const reportUserModal = useReportUser();
		const isFollowingPostUser = useFollowingUser();
		const userFavoritePost = useUserFavoritePost();
		const { data: numbersOfLikesByPostUser, queryKey: useNumbersOfLikesByUserKey } = useNumbersOfLikesByUser(undefined);
		const { data: ngWords } = useNgWords();
		const { data: postUser, queryKey: useUserKey } = useUser(undefined);
		/** @type {t.User} */
		let user;
		let timeout = null;

		watchEffect(() => {
			// NOTE wait 3s before goto error page, maybe it retry 3,5 times
			if (state.isFirstFetchPostFinished === true && postStatus.value !== 'loading' && !post.value) {
				router.replace({ name: 'error_404' });
				return;
			}
			if (post.value?.undisplay_flag === 1 && post.value.user_id !== globalUserId) {
				router.replace({ name: 'error_404' });
			}
			if (post.value?.user_id) {
				userApi.getByDocId(post.value?.user_id).then(doc => {
					user = doc;
				});
			}
		});

		watchEffect(() => {
			const comments = commentsQuery.data.value;
			if (!comments) return;
			Object.values(comments).forEach(com => {
				if (!com.reply_comment_id) {
					state.replyComment[com.docId] = {
						value: '',
						error: '',
						showing: com.replyComments.length >= 1
					};
				}
			});
		});

		const checkNotContainNgWords = (word = '') => {
			return fDefined(ngWords.value).every(w => !word.includes(w.ng_word));
		};

		const toggleFollowUserAction = async () => {
			try {
				await followApi.toggleFollowUser({
					user_id: fDefined(globalState.user.id),
					follow_user_id: fDefined(post.value).user_id
				});
				queryClient.invalidateQueries('is_follow');
			} catch (error) {
				console.log(error);
			}
		};

		const deletePostAction = async () => {
			if (window.confirm('投稿を削除しますか?') === false) return;
			try {
				assert(post.value);
				await postApi.deleteByDocId(post.value.docId);
				await router.push({
					name: 'puzzle',
					query: { activePuzzleId: fDefined(post.value.puzzle_id) }
				});
			} catch (error) {
				console.log(error);
			}
		};

		const likePostAction = () => {
			state.likeCount += 1;
			if (fDefined(globalState.user.id) !== fDefined(post.value).user_id) {
				const nowDateObject = dayjs().toDate();
				if (timeout) {
					clearTimeout(timeout);
				}
				timeout = setTimeout(async () => {
					try {
						const likeCount = await likeApi.toggleLikePost({
							post_id: fDefined(post.value).docId,
							user_id: fDefined(globalState.user.id)
						});
						notificationApi.createNotification({
							target_id: fDefined(post.value).docId,
							target_img: fDefined(post.value).post_img,
							user_id: fDefined(post.value).user_id,
							sender_id: fDefined(globalState.user.id),
							notification_type: NOTIFICATION_TYPE.LIKE,
							like_count: likeCount,
							delete_flag: 0,
							created_at: nowDateObject,
							updated_at: nowDateObject
						});
						queryClient.invalidateQueries('post');
						if (user && !user.off_notification && user.message_token) {
							const data = {
								to: user.message_token,
								data: {
									title: 'いいねがもらいました。',
									icon: fDefined(post.value).post_img,
									link: `${window.location.origin}/posts/${fDefined(post.value).docId}`
								}
							};
							sendNoti(data);
						}
					} catch (error) {
						showError();
					}
				}, 1000);
			}
		};

		const toggleFavoritePostAction = async () => {
			try {
				const postCreatedaAt = new Date(f(post.value?.created_at.seconds) * 1000);
				await favoriteApi.toggleFavoritePost({
					user_id: fDefined(globalState.user.id),
					post_id: fDefined(post.value).docId,
					post_img: fDefined(post.value).post_img,
					post_created_at: postCreatedaAt
				});
				queryClient.invalidateQueries('post_favorite');
			} catch (error) {
				showError();
			}
		};

		const postCommentAction = async () => {
			const nowDateObject = dayjs().toDate();
			try {
				assert(post.value);
				assert(globalState.user.id);
				if (!state.postComment.value) {
					return;
				}
				const checkNotContainNgWord = checkNotContainNgWords(state.postComment.value);
				if (!checkNotContainNgWord) {
					state.postComment.error = '禁止ワードが含まれているため、コメントを投稿できません。';
					return;
				}

				// @ts-ignore
				// @ts-ignore
				const result = await commentApi.create({
					comment: state.postComment.value,
					reply_comment_id: null,
					post_id: post.value.docId,
					user_id: globalState.user.id,
					post_index: `${post.value?.puzzle_index}-${post.value?.piece_id}`,
					puzzle_id: post.value.puzzle_id,
					puzzle_index: f(post.value.puzzle_index),
					piece_id: f(post.value.piece_id),
					post_user_id: post.value.user_id
				});
				if (fDefined(globalState.user.id) !== fDefined(post.value).user_id) {
					notificationApi.createNotification({
						target_id: fDefined(post.value).docId,
						target_img: fDefined(post.value).post_img,
						user_id: fDefined(post.value).user_id,
						sender_id: fDefined(globalState.user.id),
						notification_type: NOTIFICATION_TYPE.COMMENT,
						delete_flag: 0,
						created_at: nowDateObject,
						updated_at: nowDateObject
					});
					if (user && !user.off_notification && user.message_token) {
						const data = {
							to: user.message_token,
							data: {
								title: '新しいコメントが来ました。',
								icon: fDefined(post.value).post_img,
								link: `${window.location.origin}/posts/${fDefined(post.value).docId}`
							}
						};
						sendNoti(data);
					}
				}
				state.postComment.value = '';
				state.postComment.error = '';
				queryClient.invalidateQueries('comments');
			} catch (error) {
				showError();
			}
		};

		const replyCommentAction = async (/** @type {t.Comment['docId']} */ commentDocId, /** @type {t.Comment['user_id']} */ userId) => {
			const nowDateObject = dayjs().toDate();
			try {
				assert(post.value);
				const replyComment = state.replyComment[commentDocId];
				if (!replyComment?.value) {
					return;
				}
				const checkNotContainNgWord = checkNotContainNgWords(replyComment.value);

				if (!checkNotContainNgWord) {
					replyComment.error = '禁止ワードが含まれているため、コメントを投稿できません。';
					return;
				}

				await commentApi.create({
					comment: replyComment.value,
					reply_comment_id: commentDocId,
					post_id: fDefined(post.value).docId,
					user_id: fDefined(globalState.user.id),
					post_index: `${post.value?.puzzle_index}-${post.value?.piece_id}`,
					puzzle_id: post.value.puzzle_id,
					puzzle_index: f(post.value.puzzle_index),
					piece_id: f(post.value.piece_id),
					post_user_id: post.value.user_id
				});
				if (fDefined(globalState.user.id) !== userId) {
					notificationApi.createNotification({
						target_id: fDefined(post.value).docId,
						target_img: fDefined(post.value).post_img,
						user_id: userId,
						sender_id: fDefined(globalState.user.id),
						notification_type: NOTIFICATION_TYPE.REPLY,
						delete_flag: 0,
						created_at: nowDateObject,
						updated_at: nowDateObject
					});
					const commentUser = await userApi.getByDocId(userId);
					if (commentUser && !commentUser.off_notification && commentUser.message_token) {
						const data = {
							to: commentUser.message_token,
							data: {
								title: 'あなたのコメントに返信が来ました。',
								icon: fDefined(post.value).post_img,
								link: `${window.location.origin}/posts/${fDefined(post.value).docId}`
							}
						};
						sendNoti(data);
					}
				}
				replyComment.value = '';
				replyComment.error = '';

				queryClient.invalidateQueries('comments');
			} catch (error) {
				showError();
			}
		};

		const { visibleList, listLength, maxPage } = usePagination(
			computed(() => {
				return Object.values(commentsQuery.data.value || []);
			}),
			{ PER_PAGE: COMMENTS.PER_PAGE }
		);

		// @ts-ignore
		// @ts-ignore
		function loadImage(url) {
			return new Promise(resolve => {
				const image = new Image();
				// image.crossOrigin = 'anonymous';
				image.addEventListener('load', () => {
					console.log(2);
					resolve(image);
				});
				image.src = url;
			});
		}

		watchEffect(() => {
			if (!post.value?.docId) return;

			state.isFirstFetchPostFinished = false;

			isFollowingPostUser.queryKey[1] = {
				follow_user_id: post.value.user_id
			};

			userFavoritePost.queryKey[1] = {
				post_id: post.value.docId
			};
			useNumbersOfLikesByUserKey[1] = {
				user_id: post.value.user_id
			};
			useUserKey[1] = {
				user_id: post.value.user_id
			};
			if (post?.value?.like_count && state.likeCount === 0) {
				state.likeCount = post.value.like_count;
			}
		});

		// ANCHOR - setup return
		return {
			f,
			post,
			isFollowingPostUser: isFollowingPostUser.query.data,
			isFollowingPostUserStatus: isFollowingPostUser.query.status,
			userFavoritePost,
			state,
			postUser,
			formatFbDate,
			numbersOfLikesByPostUser,
			getAvatarImg,
			globalState,
			Object,
			toggleFollowUserAction,
			reportUserModal,
			deletePostAction,
			REPORT_TYPE,
			toggleFavoritePostAction,
			postCommentAction,
			replyCommentAction,
			likePostAction,
			// ANCHOR - computed
			isLoggedInUser: computed(() => {
				return postUser.value?.docId === globalState.user.id;
			}),
			likeIcon: computed(() => {
				const count = state.likeCount;

				// eslint-disable-next-line import/no-dynamic-require
				return require(`@/assets/images/icons/${count % 100}.png`);
			}),
			starYellowIconSrc: computed(() => {
				return userFavoritePost.query.data.value
					? // @ts-ignore
					  require('@/assets/images/gold-star-icon.png')
					: // @ts-ignore
					  require('@/assets/images/blank-star-icon.png');
			}),
			listLength,
			maxPage,
			visibleList,
			CON_FIG: {
				COMMENT_PER_PAGE: COMMENTS.PER_PAGE
			},
			fDefined,
			throttle
		};
	}
};
</script>
<template>
	<div class="flex flex-1 p-4">
		<div class="w-full lg:mx-8">
			<report-user v-if="reportUserModal.state.showingModal"></report-user>
			<div class="flex flex-row items-center justify-between mb-4 lg:mb-32px lg:mt-49px">
				<div class="w-full">
					<div class="flex flex-row items-center mb-4 md:mb-0 lg:mr-0">
						<div class="items-center w-20 h-20 lg:w-32 lg:h-32 mr-17px">
							<router-link :to="{ name: 'my_page', params: { userId: postUser?.docId } }">
								<img :src="getAvatarImg(postUser?.icon_img)" alt="User Avatar" class="object-cover w-full h-full rounded-full" />
								<img v-if="getAvatarImg(postUser?.user_type) === 2" src="@/assets/images/supporter.png" alt="supporter" />
								<img v-if="getAvatarImg(postUser?.user_type) === 3" src="@/assets/images/special-supporter.png" alt="supporter" />
							</router-link>
						</div>
						<div class="flex flex-col justify-center flex-1">
							<p class="text-xl tracking-wider lg:text-2xl">
								{{ postUser?.nickname }}
							</p>
							<div class="flex flex-row items-center hidden lg:mt-4 lg:mt-0 lg:flex">
								<img src="@/assets/images/heart-white-icon.png" alt="like count icon" class="w-8 mr-2" />

								<p class="text-base">いいねした数：{{ numbersOfLikesByPostUser }}</p>
							</div>
						</div>
						<div v-if="isFollowingPostUserStatus === 'success' && !isLoggedInUser">
							<button
								class="flex items-center justify-center mx-auto text-base font-extrabold text-white bg-center bg-no-repeat bg-contain lg:text-xl md:h-24 bg-button-blue lg:w-300px hover:bg-button-yellow focus:outline-none hover:text-blue-400 w-140px h-50px"
								@click="toggleFollowUserAction"
							>
								{{ isFollowingPostUser ? 'フォロー中' : 'フォローする' }}
							</button>
						</div>
					</div>
					<div class="flex flex-row items-center lg:hidden lg:mt-6 lg:mt-0">
						<img src="@/assets/images/heart-white-icon.png" alt="like count icon" class="w-8 mr-2" />
						<p class="text-base">いいねした数：{{ numbersOfLikesByPostUser }}</p>
					</div>
				</div>
				<!-- ANCHOR - Follow Action -->
			</div>

			<!--
			<div class="flex flex-col justify-between mb-4 lg:flex-row lg:px-6">
				<div v-if="post" class="font-bold text-18px lg:w-40 mb-4">{{ `${post?.puzzle_index}-${post?.piece_id}` }} {{ post?.post_title }}</div>
				<div v-if="!isLoggedInUser" class="flex flex-row justify-between">
					<div style="margin-top: -10px" @click="likePostAction">
						<button type="button" class="flex flex-row items-center focus:outline-none mr-47px touch-malnipulation">
							<img :src="likeIcon" class="w-14 mr-1" alt="like icon" />
							<span class="text-18px">{{ post?.like_count || 0 }} いいね</span>
						</button>
					</div>
					<div @click="toggleFavoritePostAction">
						<button type="button" class="flex flex-row items-center focus:outline-none">
							<img :src="starYellowIconSrc" class="w-9 mr-1" alt="favorite icon" />
							<span class="text-18px">お気に入り</span>
						</button>
					</div>
				</div>
			</div> -->

			<div class="flex flex-col justify-between mb-4">
				<div v-if="post?.is_last_high_priority_posted" class="text-center text-18px  font-bold text-asp-pink">完成！おめでとう！！</div>
				<div v-if="post" class="font-bold text-18px mb-4">{{ `${post?.puzzle_index}-${post?.piece_id}` }} {{ post?.post_title }}</div>
				<div v-if="!isLoggedInUser" class="flex flex-row justify-between lg:justify-end items-center">
					<div @click="likePostAction">
						<button type="button" class="flex flex-row items-center focus:outline-none mr-47px touch-malnipulation">
							<img :src="likeIcon" class="w-14 mr-1" alt="like icon" /> <span class="text-18px">{{ state.likeCount }} いいね</span>
						</button>
					</div>
					<div @click="toggleFavoritePostAction">
						<button type="button" class="flex flex-row items-center focus:outline-none">
							<img :src="starYellowIconSrc" class="w-9 mr-1" alt="favorite icon" /> <span class="text-18px">お気に入り</span>
						</button>
					</div>
				</div>
			</div>

			<!-- aspect-w-1 aspect-h-1  -->
			<div v-if="post?.undisplay_flag !== 1" class="mb-4 overflow-hidden border-0 rounded-md border-asp-brown aspect-w-1 aspect-h-1 bg-asp-black z-0">
				<div class="absolute expand-container flex items-center ">
					<expandable-image v-if="post?.post_img" class="" :src="post?.post_img" />
				</div>
			</div>
			<div v-if="post?.undisplay_flag === 1" class="mb-4 overflow-hidden border rounded-md border-asp-brown relative aspect-w-1 aspect-h-1 ">
				<div class=" flex justify-center items-center">
					<span class="z-10 text-2xl text-white ">非公開中</span>
					<img
						v-if="post?.post_img"
						class="object-cover w-full h-full absolute inset-0 "
						:src="post?.post_img"
						:class="[post?.undisplay_flag === 1 && post?.user_id === globalState.user?.id ? 'filter grayscale blur-sm' : '']"
					/>
				</div>
			</div>

			<div class="flex flex-row justify-between mb-6">
				<div class="flex flex-col pr-4">
					<h3 class="mb-1 break-words word-break text-14px whitespace-pre-line">
						{{ post?.post_message }}
					</h3>
					<span class="text-asp-9FA0A0 text-12px">{{ formatFbDate(post?.created_at) }}</span>
				</div>
				<div class="flex flex-col lg:flex-row items-start">
					<button
						v-if="isLoggedInUser"
						type="button"
						class="flex lg:flex hidden flex-row items-center justify-center p-2 font-bold rounded-full border-3 border-asp-brown focus:outline-none lg:w-167px lg:h-41px w-100px lg:mr-10px mr-0 mb-2 lg:mb-0"
						@click="$router.push({ name: 'posts_edit', params: { id: fDefined(post?.docId) } })"
					>
						<img class="h-20px lg:mr-10px mr-0" src="@/assets/images/pencil-black-icon.png" alt="edit icon" />
						<span class="text-xs font-bold">編集する</span>
					</button>
					<button
						v-if="isLoggedInUser"
						type="button"
						class="flex lg:hidden block flex-row items-center justify-center p-2 font-bold rounded-full border-3 border-asp-brown focus:outline-none lg:w-167px lg:h-41px lg:w-100px w-70px lg:mr-10px mr-0 mb-2 lg:mb-0"
						@click="$router.push({ name: 'posts_edit', params: { id: fDefined(post?.docId) } })"
					>
						<!-- <img class="h-20px lg:mr-10px mr-0" src="@/assets/images/pencil-black-icon.png" alt="edit icon" /> -->
						編集
					</button>
					<button
						v-if="isLoggedInUser"
						type="button"
						class="flex flex-row items-center hidden lg:flex  justify-center p-2 font-bold rounded-full border-3 border-asp-brown focus:outline-none lg:w-167px lg:w-100px w-70px lg:h-41px"
						@click="deletePostAction"
					>
						<img class="h-20px lg:mr-10px mr-0" src="@/assets/images/trash-black-icon.png" alt="edit icon" />
						<span class="text-xs font-bold">削除する</span>
					</button>

					<button
						v-if="isLoggedInUser"
						type="button"
						class="flex flex-row lg:hidden block items-center justify-center p-2 font-bold rounded-full border-3 border-asp-brown focus:outline-none lg:w-167px lg:w-100px w-70px lg:h-41px"
						@click="deletePostAction"
					>
						<img class="h-20px lg:mr-10px mr-0" src="@/assets/images/trash-black-icon.png" alt="edit icon" />
						<!-- <span class="text-xs font-bold">削除する</span> -->
					</button>

					<!-- #ANCHOR - report post -->
					<button
						v-if="!isLoggedInUser"
						type="button"
						class="flex flex-row lg:flex hidden items-center justify-center p-2 ml-4 font-bold rounded-full lg:ml-8 focus:outline-none lg:w-167px lg:w-120px w-70px lg:h-41px text-asp-white-gray border-3 border-asp-white-gray"
						@click="
							reportUserModal.openModal({
								report_type: REPORT_TYPE.POST,
								post: fDefined(post),
								post_user_id: f(post?.user_id),
								puzzle_index: f(post?.puzzle_index),
								piece_id: f(post?.piece_id),
								puzzle_id: f(post?.puzzle_id)
							})
						"
					>
						<img src="@/assets/images/flag-gray-icon.png" alt="report flag" />
						<span class="ml-3 text-xs font-bold lg:text-base">通報する </span>
					</button>
					<button
						v-if="!isLoggedInUser"
						type="button"
						class="flex flex-row block lg:hidden items-center justify-center p-2 ml-4 font-bold rounded-full lg:ml-8 focus:outline-none lg:w-167px lg:w-120px w-70px lg:h-41px text-asp-white-gray border-3 border-asp-white-gray"
						@click="
							reportUserModal.openModal({
								report_type: REPORT_TYPE.POST,
								post: fDefined(post),
								post_user_id: f(post?.user_id),
								puzzle_index: f(post?.puzzle_index),
								piece_id: f(post?.piece_id),
								puzzle_id: f(post?.puzzle_id)
							})
						"
					>
						通報
						<!-- <span class="ml-3 text-xs font-bold lg:text-base">通報する </span> -->
					</button>
				</div>
			</div>
			<!-- #ANCHOR -login user post comment -->
			<div class="flex flex-row items-center mb-6">
				<div class="flex items-start">
					<img :src="getAvatarImg(globalState.user.icon_img)" alt="user avatar" class="mr-2 rounded-full lg:mr-6 w-62px h-62px" />
				</div>
				<div class="flex-1">
					<textarea
						v-model="state.postComment.value"
						:min-height="20"
						:max-height="150"
						:maxlength="50"
						class="block w-full px-3 py-2 border border-gray-700 rounded resize-none focus:outline-none"
						placeholder="応援メッセージ(50文字以内)を追加…"
					/>
					<div class="mt-1 text-red-500">
						{{ state.postComment.error }}
					</div>
				</div>
				<button type="button" class="py-2 ml-2 text-sm font-bold lg:ml-12 lg:text-xl focus:outline-none text-asp-blue" @click="postCommentAction">
					投稿する
				</button>
			</div>
			<div class="w-full">
				<div class="comment-list">
					<div v-for="comment in visibleList" :key="comment.docId">
						<!-- #ANCHOR - show post comments -->

						<div class="flex flex-row mb-6">
							<div class="flex items-start h-full mr-2 lg:mr-6">
								<router-link :to="{ name: 'my_page', params: { userId: comment.user_id } }">
									<img :src="getAvatarImg(comment?.user?.icon_img)" alt="user avatar" class="object-cover rounded-full w-62px h-62px" />
								</router-link>
							</div>
							<div class="flex flex-col flex-1">
								<p class="mr-5 font-bold">
									{{ comment?.user?.nickname }}
								</p>
								<p class="break-all whitespace-pre-line">
									{{ comment.comment }}
								</p>
								<p>
									<span class="text-gray-300">
										{{ formatFbDate(comment?.created_at) }}
									</span>
									<button
										v-if="comment?.replyComments?.length === 0"
										class="ml-4 text-xs font-semibold cursor-pointer focus:outline-none lg:text-base"
										@click.self="state.replyComment[comment.docId].showing = true"
									>
										返信
									</button>
								</p>
							</div>
							<div>
								<button
									v-if="comment.user_id !== globalState.user.id"
									type="button"
									class="flex flex-row items-center justify-center hidden lg:flex ml-2 font-bold rounded-full lg:ml-8 focus:outline-none lg:w-167px lg:w-120px w-70px h-41px text-asp-white-gray border-3 border-asp-white-gray"
									@click="
										reportUserModal.openModal({
											report_type: REPORT_TYPE.COMMENT,
											comment: comment,
											post_user_id: f(post?.user_id),
											puzzle_index: f(post?.puzzle_index),
											piece_id: f(post?.piece_id),
											puzzle_id: f(post?.puzzle_id)
										})
									"
								>
									<img src="@/assets/images/flag-gray-icon.png" alt="report flag" />
									<span class="ml-3 text-xs font-bold lg:text-base">通報する</span>
								</button>
								<button
									v-if="comment.user_id !== globalState.user.id"
									type="button"
									class="flex flex-row items-center justify-center lg:hidden block ml-2 font-bold rounded-full lg:ml-8 focus:outline-none lg:w-167px lg:w-120px w-70px h-41px text-asp-white-gray border-3 border-asp-white-gray"
									@click="
										reportUserModal.openModal({
											report_type: REPORT_TYPE.COMMENT,
											comment: comment,
											post_user_id: f(post?.user_id),
											puzzle_index: f(post?.puzzle_index),
											piece_id: f(post?.piece_id),
											puzzle_id: f(post?.puzzle_id)
										})
									"
								>
									<img src="@/assets/images/flag-gray-icon.png" alt="report flag" />
									<!-- <span class="ml-3 text-xs font-bold lg:text-base">通報する</span> -->
								</button>
							</div>
						</div>

						<!-- #ANCHOR - show reply comments -->
						<div v-for="replyComment in comment.replyComments" :key="replyComment.docId" class="flex flex-row items-center mb-6 ml-4 lg:ml-14">
							<div class="flex items-start h-full mr-2 lg:mr-6">
								<router-link :to="{ name: 'my_page', params: { userId: replyComment.user_id } }">
									<img :src="getAvatarImg(replyComment?.user?.icon_img)" alt="user avatar" class="object-cover rounded-full w-62px h-62px" />
								</router-link>
							</div>
							<div class="flex flex-col flex-1">
								<p class="mr-5 font-bold">
									{{ replyComment?.user?.nickname }}
								</p>
								<p class="break-words whitespace-pre-line word-break">
									{{ replyComment.comment }}
								</p>
								<p>
									<span class="text-gray-300">
										{{ formatFbDate(replyComment?.created_at, 'YYYY/MM/DD HH:mm') }}
									</span>
									<!-- <button
										class="ml-4 text-xs text-base font-semibold cursor-pointer focus:outline-none lg:text-base"
									>
										Reply
									</button> -->
								</p>
							</div>
							<div>
								<button
									v-if="replyComment.user_id !== globalState.user.id"
									type="button"
									class="flex flex-row items-center hidden lg:flex justify-center p-2 ml-4 font-bold rounded-full lg:ml-8 focus:outline-none lg:w-167px lg:h-41px w-120px text-asp-white-gray border-3 border-asp-white-gray"
									@click="
										reportUserModal.openModal({
											report_type: REPORT_TYPE.COMMENT,
											comment: replyComment,
											post_user_id: f(post?.user_id),
											puzzle_index: f(post?.puzzle_index),
											piece_id: f(post?.piece_id),
											puzzle_id: f(post?.puzzle_id)
										})
									"
								>
									<img src="@/assets/images/flag-gray-icon.png" alt="report flag" />
									<span class="ml-3 text-xs font-bold lg:text-base">通報する</span>
								</button>
								<button
									v-if="replyComment.user_id !== globalState.user.id"
									type="button"
									class="flex flex-row items-center block lg:hidden justify-center p-2 ml-4 font-bold rounded-full lg:ml-8 focus:outline-none lg:w-167px lg:h-41px w-120px w-70px text-asp-white-gray border-3 border-asp-white-gray"
									@click="
										reportUserModal.openModal({
											report_type: REPORT_TYPE.COMMENT,
											comment: replyComment,
											post_user_id: f(post?.user_id),
											puzzle_index: f(post?.puzzle_index),
											piece_id: f(post?.piece_id),
											puzzle_id: f(post?.puzzle_id)
										})
									"
								>
									<img src="@/assets/images/flag-gray-icon.png" alt="report flag" />
								</button>
							</div>
						</div>
						<!-- end relply comment -->
						<!-- #ANCHOR  - loggedin user reply -->
						<div v-if="state.replyComment[comment.docId]?.showing" class="flex flex-row items-center pl-4 mb-6 lg:mb-8 lg:pl-14">
							<div class="flex items-start h-full mr-2 lg:mr-6">
								<img :src="getAvatarImg(globalState?.user?.icon_img)" alt="user avatar" class="object-cover rounded-full w-62px h-62px" />
							</div>
							<div class="flex-1">
								<textarea
									v-model="state.replyComment[comment.docId].value"
									:min-height="20"
									:max-height="150"
									:maxlength="50"
									class="block w-full px-3 py-2 border border-gray-700 resize-none focus:outline-none"
									placeholder="応援メッセージ(50文字以内)を追加…"
								/>
								<div class="mt-1 text-red-500">
									{{ state.replyComment[comment.docId]?.error }}
								</div>
							</div>
							<button type="button" class="py-2 ml-2 text-sm font-bold lg:text-xl focus:outline-none text-asp-blue lg:ml-10" @click="replyCommentAction(comment.docId, comment.user_id)">
								投稿する
							</button>
						</div>
						<!-- loggedin user reply -->
					</div>
				</div>
				<!-- ANCHOR -  pagination -->
				<!-- <div
					v-if="state.commentsStatus !== 'loading' && commentList.length === 0"
					class="flex justify-center items-center"
				>
					データがありません
				</div> -->

				<pagination v-if="maxPage > 1" goto-route-name="posts_detail" :params="{ id: $route.params.id }" :total-record="listLength" :per-page="CON_FIG.COMMENT_PER_PAGE" class="mb-8" />
			</div>
		</div>
	</div>
</template>

<style>
[v-cloak] {
	display: none;
}
.word-break {
	word-break: break-word;
}
.touch-malnipulation {
	touch-action: manipulation;
}
.expand-container {
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
</style>
