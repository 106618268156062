<script>
import { PAGINATE } from '@/constants';
import LeftArrowIcon from '@/assets/icons/LeftArrowIcon.vue';
import LeftDoubleArrowIcon from '@/assets/icons/LeftDoubleArrowIcon.vue';
import RightArrowIcon from '@/assets/icons/RightArrowIcon.vue';
import RightDoubleArrowIcon from '@/assets/icons/RightDoubleArrowIcon.vue';
// import ThreeDotIcon from '@/assets/icons/ThreeDotIcon.vue';

export default {
	components: {
		LeftArrowIcon,
		LeftDoubleArrowIcon,
		RightArrowIcon,
		RightDoubleArrowIcon
		// ThreeDotIcon
	},
	props: {
		gotoRouteName: {
			type: String,
			required: true
		},
		params: {
			type: Object,
			default() {
				return {};
			},

			required: false
		},
		totalRecord: {
			type: Number,
			default: 0
		},
		perPage: {
			type: Number,
			required: false,
			default: PAGINATE.PER_PAGE
		}
	},
	data() {
		return {
			searchText: this.$route.query.search ? this.$route.query.search : null
		};
	},
	computed: {
		maxPage() {
			if (this.totalRecord % this.perPage === 0) return this.totalRecord / this.perPage;
			return (this.totalRecord - (this.totalRecord % this.perPage)) / this.perPage + 1;
		},
		fixedListPage() {
			if (this.maxPage <= (PAGINATE.BUFFER_PAGE + PAGINATE.FIXED_PAGE) * 2 + 1) return Array.apply(0, Array(this.maxPage)).map((element, index) => index + 1);

			if (!this.showLeftBuffer) {
				return Array.apply(0, Array(PAGINATE.FIXED_PAGE * 2 + PAGINATE.BUFFER_PAGE + 1)).map((element, index) => index + 1);
			}

			if (!this.showRightBuffer) {
				return Array.apply(0, Array(PAGINATE.FIXED_PAGE * 2 + PAGINATE.BUFFER_PAGE + 1)).map((element, index) => index + this.maxPage - PAGINATE.FIXED_PAGE * 2 - PAGINATE.BUFFER_PAGE);
			}

			return Array.apply(0, Array(PAGINATE.FIXED_PAGE * 2 + 1)).map((element, index) => index + this.currentPage - PAGINATE.FIXED_PAGE);
		},
		showLeftBuffer() {
			return this.currentPage > PAGINATE.BUFFER_PAGE + PAGINATE.FIXED_PAGE + 1;
		},
		leftBufferListPage() {
			if (this.showLeftBuffer) {
				return Array.apply(0, Array(PAGINATE.BUFFER_PAGE)).map((element, index) => index + 1);
			}

			return [];
		},
		showRightBuffer() {
			return this.currentPage < this.maxPage - PAGINATE.BUFFER_PAGE - PAGINATE.FIXED_PAGE;
		},
		rightBufferListPage() {
			if (this.showRightBuffer) {
				return Array.apply(0, Array(PAGINATE.BUFFER_PAGE)).map((element, index) => index + this.maxPage - 1);
			}

			return [];
		},
		currentPage() {
			const queryPage = parseInt(this.$route.query.page, 10);
			if (!queryPage || queryPage < 1) return 1;
			if (queryPage > this.maxPage) return this.maxPage;
			return queryPage;
		},
		nextPage() {
			return this.currentPage + 1 > this.maxPage ? this.maxPage : this.currentPage + 1;
		},
		previousPage() {
			return this.currentPage - 1 < 1 ? 1 : this.currentPage - 1;
		}
	}
};
</script>

<template>
	<div class="flex flex-row items-center justify-center">
		<div class="flex justify-center lg:h-20 h-auto pt-4">
			<router-link
				:class="currentPage === 1 ? 'cursor-not-allowed opacity-25' : ''"
				:to="{
					name: gotoRouteName,
					params: params,
					query: { search: searchText, page: 1 }
				}"
				class="flex items-center justify-center lg:w-10 lg:h-10 w-5 h-5 text-sm m-1 bg-gray-300 rounded-full cursor-pointer hover:bg-gray-400"
			>
				<left-double-arrow-icon class="lg:h-4 h-2 lg:w-4 w-2 "> </left-double-arrow-icon>
			</router-link>
			<router-link
				:class="currentPage === 1 ? 'cursor-not-allowed opacity-25' : ''"
				:to="{
					name: gotoRouteName,
					params: params,
					query: { search: searchText, page: previousPage }
				}"
				class="flex items-center justify-center lg:w-10 lg:h-10 w-5 h-5 text-sm m-1 bg-gray-300 rounded-full cursor-pointer hover:bg-gray-400"
			>
				<left-arrow-icon class="lg:w-6 lg:h-6 w-3 h-3"> </left-arrow-icon>
			</router-link>
			<router-link
				v-for="pageNumber in leftBufferListPage"
				:key="pageNumber"
				:to="{
					name: gotoRouteName,
					params: params,
					query: { search: searchText, page: pageNumber }
				}"
				:class="pageNumber === currentPage ? 'bg-gray-400' : ''"
				class="flex items-center justify-center lg:w-10 lg:h-10 w-5 h-5 text-sm m-1 bg-gray-300 rounded-full cursor-pointer hover:bg-gray-400"
			>
				{{ pageNumber }}
			</router-link>
			<!-- <div v-if="showLeftBuffer" class="flex items-end mx-2">
				<three-dot-icon class="fill-current h-5"></three-dot-icon>
			</div> -->
			<router-link
				v-for="pageNumber in fixedListPage"
				:key="pageNumber"
				:to="{
					name: gotoRouteName,
					params: params,
					query: { search: searchText, page: pageNumber }
				}"
				:class="pageNumber === currentPage ? 'bg-gray-400' : 'bg-gray-300'"
				class="flex items-center justify-center lg:w-10 lg:h-10 w-5 h-5 text-sm m-1  rounded-full cursor-pointer hover:bg-gray-400"
			>
				{{ pageNumber }}
			</router-link>
			<!-- <div v-if="showRightBuffer" class="flex items-end mx-2">
				<three-dot-icon class="fill-current h-5"></three-dot-icon>
			</div> -->
			<router-link
				v-for="pageNumber in rightBufferListPage"
				:key="pageNumber"
				:to="{
					name: gotoRouteName,
					params: params,
					query: { search: searchText, page: pageNumber }
				}"
				:class="pageNumber === currentPage ? '' : 'bg-gray-400'"
				class="flex items-center justify-center lg:w-10 lg:h-10 w-5 h-5 text-sm m-1 bg-gray-300 rounded-full cursor-pointer hover:bg-gray-400"
			>
				{{ pageNumber }}
			</router-link>
			<router-link
				:class="currentPage === maxPage ? 'cursor-not-allowed opacity-25' : ''"
				:to="{
					name: gotoRouteName,
					params: params,
					query: { search: searchText, page: nextPage }
				}"
				class="flex items-center justify-center lg:w-10 lg:h-10 w-5 h-5 text-sm m-1 bg-gray-300 rounded-full cursor-pointer hover:bg-gray-400"
			>
				<right-arrow-icon class="lg:w-6 lg:h-6 w-3 h-3 "> </right-arrow-icon>
			</router-link>
			<router-link
				:class="currentPage === maxPage ? 'cursor-not-allowed opacity-25' : ''"
				:to="{
					name: gotoRouteName,
					params: params,
					query: { search: searchText, page: maxPage }
				}"
				class="flex items-center justify-center lg:w-10 lg:h-10 w-5 h-5 text-sm m-1 bg-gray-300 rounded-full cursor-pointer hover:bg-gray-400"
			>
				<right-double-arrow-icon class="lg:w-4 lg:h-4 w-2 h-2 "> </right-double-arrow-icon>
			</router-link>
		</div>
	</div>
</template>
