import commentApi from '@/apis/commentApi';
import favoriteApi from '@/apis/favoriteApi';
import followApi from '@/apis/followApi';
import likeApi from '@/apis/likeApi';
import messageApi from '@/apis/messageApi';
import ngWordApi from '@/apis/ngWordApi';
import notificationApi from '@/apis/notificationApi';
import postApi from '@/apis/postApi';
import prefectureApi from '@/apis/prefectureApi';
import reportApi from '@/apis/reportApi';
import userApi from '@/apis/userApi';
import { PAGINATE, REPORT } from '@/constants';
import router from '@/router';
import { f, fDefined, waitFor } from '@/services/utils';
import { globalState } from '@/stores/global';
import { keyBy } from 'lodash';
import { computed, reactive } from 'vue';
import { useQuery } from 'vue-query';

// ANCHOR follow

export const followQuery = {
	/**
	 * @param {t.User['docId']=} userId
	//  * @returns {['follows:followings', { user_id: userId }]}
	 */
	useFollowings: userId => ['follows:followings', { user_id: userId }],
	/**
	 * @param {t.User['docId']=} userId
	//  * @returns {['follows:followings', { user_id: userId,withUser: true  }]}
	 */
	useFollowingsWithUserInfo: userId => ['follows:followings', { user_id: userId, withUser: true }],
	/**
	 * @param {t.User['docId']=} userId
	//  * @returns {['follows:followers', { user_id: userId }]}
	 */
	useFollowers: userId => ['follows:followers', { user_id: userId }],
	/**
	 * @param {t.User['docId']=} userId
	//  * @returns {['follows:followers', { user_id: userId,withUser: true  }]}
	 */
	useFollowersWithUserInfo: userId => ['follows:followers', { user_id: userId, withUser: true }]
};

/** @param {t.User['docId']|undefined} userId */
export const useFollowings = userId => {
	/** @type {t.VueQuery.Query['follows:followings']['Key']} */
	const queryKey = reactive(followQuery.useFollowings(userId));

	const queryFn = async () => {
		return followApi.getFollowingByUser({
			user_id: fDefined(queryKey[1].user_id)
		});
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!(queryKey[1].user_id))
	});

	const query = useQuery(queryKey, queryFn, options);

	return { ...query, queryKey };
};
/** @param {t.User['docId']|undefined} userId */
export const useFollowingsWithUserInfo = userId => {
	/** @type {t.VueQuery.Query['follows:followings']['Key']} */
	const queryKey = reactive(followQuery.useFollowingsWithUserInfo(userId));

	const queryFn = async () => {
		const data = await followApi.getFollowingByUser({ user_id: fDefined(queryKey[1].user_id) });

		const usersMap = await userApi.getByChunkingIds({
			ids: data.map(doc => doc.follow_user_id)
		});

		return data.map(follow => ({
			...follow,
			user: usersMap[follow.follow_user_id]
		}));
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!(queryKey[1].user_id))
	});

	const query = useQuery(queryKey, queryFn, options);

	return { ...query, queryKey };
};

/** @param {t.User['docId']|undefined} userId */
export const useFollowers = userId => {
	const queryKey = reactive(followQuery.useFollowers(userId));

	const queryFn = async () => {
		return followApi.getFollowerByUser({
			user_id: fDefined(queryKey[1].user_id)
		});
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!(queryKey[1].user_id))
	});

	const query = useQuery(queryKey, queryFn, options);

	return { ...query, queryKey };
};

/** @param {t.User['docId']|undefined} userId */
export const useFollowersWithUserInfo = userId => {
	const queryKey = reactive(followQuery.useFollowersWithUserInfo(userId));

	const queryFn = async () => {
		const [followers, followingUsers] = await Promise.all([
			followApi.getFollowerByUser({ user_id: f(queryKey[1].user_id) }),
			followApi.getFollowingByUser({
				user_id: f(queryKey[1].user_id)
			})
		]);
		const usersMap = await userApi.getByChunkingIds({
			ids: followers.map(doc => doc.user_id)
		});
		const followingUsersMap = keyBy(followingUsers, follow => follow.follow_user_id);

		return followers.map(follow => ({
			...follow,
			user: usersMap[follow.user_id],
			loginUserFollowDocId: followingUsersMap[follow.user_id]
		}));
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!(queryKey[1].user_id))
	});

	const query = useQuery(queryKey, queryFn, options);

	return { ...query, queryKey };
};

export const useFollowingUser = (/** @type {t.Follow['follow_user_id']|undefined} */ followerUserId) => {
	const queryKey = reactive(['is_follow', { follow_user_id: followerUserId }]);
	const queryFn = async () => {
		return followApi.getFollowRecord({
			user_id: globalState.user.id,
			follow_user_id: queryKey[1].follow_user_id
		});
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!(queryKey[1].follow_user_id))
	});

	const query = useQuery(queryKey, queryFn, options);

	return { queryKey, query };
};

// ANCHOR favorite

/** @param {t.User['docId']|undefined} userId */
export const useFavorites = userId => {
	/** @type {t.VueQuery.Query['favorites']['Key']} */
	const queryKey = reactive(['favorites', { user_id: userId }]);

	const queryFn = async () => {
		return favoriteApi.getAllByUser({
			user_id: fDefined(queryKey[1].user_id)
		});
	};
	// FIXME report post

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!(queryKey[1].user_id))
	});

	const query = useQuery(queryKey, queryFn, options);

	return { queryKey, ...query };
};

// ANCHOR likes
// export const likeKey = {

// 	useLikesToPost: (/** @type {t.Post['docId']=} */ postId) => ['likes:count_to_post', { postId }]
// };

export const useUserLikePost = () => {
	/** @type {t.VueQuery.UseQuery['POST_LIKE']['queryKey']} */
	const queryKey = reactive(['post_like', { post_id: undefined }]);
	/** @return {Promise<t.VueQuery.UseQuery['POST_LIKE']['queryFnReturn']>} */
	const queryFn = async () => {
		return likeApi.getByPost({
			user_id: globalState.user.id,
			post_id: queryKey[1].post_id
		});
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!(queryKey[1].post_id))
	});

	const query = useQuery(queryKey, queryFn, /** @type {object} */ (options));

	return { queryKey, query };
};

// export const useLikesToPost = () => {
// 	const queryKey = reactive(['post_like', { post_id: undefined }]);
// 	/** @return {Promise<t.VueQuery.UseQuery['POST_LIKE']['queryFnReturn']>} */
// 	const queryFn = async () => {
// 		return likeApi.getByPost({
// 			user_id: globalState.user.id,
// 			post_id: queryKey[1].post_id
// 		});
// 	};

// 	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
// 	const options = reactive({
// 		enabled: computed(() => !!(queryKey[1].post_id))
// 	});

// 	const query = useQuery(queryKey, queryFn, /** @type {object} */ (options));

// 	return { queryKey, query };
// };

export const useUserFavoritePost = () => {
	/** @type {t.VueQuery.UseQuery['POST_FAVORITE']['queryKey']} */
	const queryKey = reactive(['post_favorite', { post_id: undefined }]);
	/** @return {Promise<t.VueQuery.UseQuery['POST_FAVORITE']['queryFnReturn']>} */
	const queryFn = async () => {
		return favoriteApi.getByPost({
			user_id: globalState.user.id,
			post_id: queryKey[1].post_id
		});
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!(queryKey[1].post_id))
	});

	const query = useQuery(queryKey, queryFn, /** @type {object} */ (options));

	return { queryKey, query };
};

export const useUserReports = () => {
	/** @type {t.VueQuery.UseQuery['USER_REPORTS']['queryKey']} */
	const queryKey = ['user_reports'];
	// /** @return {Promise<t.VueQuery.UseQuery['USER_REPORTS']['queryFnReturn']>} */
	const queryFn = async () => {
		return reportApi.getByUserId({
			user_id: globalState.user.id
		});
	};

	const query = useQuery(queryKey, queryFn, {});

	return { query };
};

export const usePrefectures = () => {
	/** @type {t.VueQuery.Query['prefectures']['Key']} */
	const queryKey = ['prefectures'];
	/** @typedef {t.Await<typeof queryFn>} UsePrefecturesQueryFnReturn */
	const queryFn = async () => {
		return prefectureApi.getAll();
	};

	const query = useQuery(queryKey, queryFn, {
		initialData: /** @type {UsePrefecturesQueryFnReturn} */ ({})
	});

	return { ...query };
};

export const useNgWords = () => {
	/** @type {t.VueQuery.Query['ngWords']['Key']} */
	const queryKey = ['ngWords'];
	const queryFn = async () => {
		return ngWordApi.getAll();
	};

	const query = useQuery(queryKey, queryFn, {
		placeholderData: []
	});

	return { ...query };
};

// ANCHOR users
/** @param {t.User['docId']|undefined} docId */
export const useNumbersOfLikesByUser = docId => {
	/** @type {t.VueQuery.Query['likes:count_by_user_id']['Key']} */
	const queryKey = reactive(['likes:count_by_user_id', { user_id: docId }]);

	const queryFn = async () => {
		return likeApi.countLikesByUser({ user_id: fDefined(queryKey[1].user_id) });
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!(queryKey[1].user_id))
	});

	const query = useQuery(queryKey, queryFn, options);

	return { ...query, queryKey };
};

/** @param {t.User['docId']|undefined} docId */
export const useUser = docId => {
	/** @type {t.VueQuery.Query['user']['Key']} */
	const queryKey = reactive(['user', { user_id: docId }]);

	const queryFn = async () => {
		return userApi.getById({ id: fDefined(queryKey[1].user_id) });
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!(queryKey[1].user_id))
	});

	const query = useQuery(queryKey, queryFn, options);

	return { ...query, queryKey };
};

export const useUndisplayUsers = () => {
	const queryKey = reactive(['users', { undisplay: true }]);

	const queryFn = async () => {
		return userApi.getAccountLockUsers();
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: true
	});

	const query = useQuery(queryKey, queryFn, options);

	return { ...query, queryKey };
};

// ANCHOR Post

export const postQuery = {
	/**
	 * @param {t.Post['docId']=} postId
	//  * @returns {['post', { post_id: postId, checkReport: true }]}
	 */
	usePost: postId => ['post', { post_id: postId, checkReport: true }]
};

/** @param {t.Post['user_id']|undefined} userId */
export const usePosts = userId => {
	/** @type {t.VueQuery.Query['posts']['Key']} */
	const queryKey = reactive(['posts', { user_id: userId }]);

	const queryFn = async () => {
		return postApi.getAllByUser({
			user_id: fDefined(queryKey[1].user_id)
		});
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!queryKey[1].user_id)
	});

	const query = useQuery(queryKey, queryFn, options);

	return { ...query, queryKey };
};

/** @param {t.Post['docId']|undefined} postId */
export const usePost = postId => {
	/** @type {t.VueQuery.Query['post']['Key']} */
	const queryKey = reactive(postQuery.usePost(postId));

	const queryFn = async () => {
		const [post, accountLockUsers] = await Promise.all([postApi.getByDocId(fDefined(queryKey[1].post_id)), userApi.getAccountLockUsers()]);
		if (accountLockUsers[fDefined(post).user_id]) {
			return null;
		}

		return post;
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!queryKey[1].post_id),
		refetchOnWindowFocus: false
	});

	const query = useQuery(queryKey, queryFn, options);

	return { ...query, queryKey };
};
// ANCHOR Comments

export const commentsKey = {
	/**
	 * @param {t.Post['docId']|undefined} postId
	 * @returns {['comments', { post_id: t.Post['docId']|undefined }]}
	 */
	useComments: postId => /** @const */ ['comments', { post_id: postId }]
};

/** @param {t.Post['docId']|undefined} postId */
export const useComments = postId => {
	const queryKey = reactive(commentsKey.useComments(postId));

	const queryFn = async () => {
		return commentApi.getByPostId(fDefined(queryKey[1].post_id));
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!queryKey[1].post_id)
	});

	const query = useQuery(queryKey, queryFn, options);

	return { ...query, queryKey };
};

// ANCHOR Messages
/** @param {t.User['docId']|undefined} userId */
export const usePublicizedMessagesByUser = userId => {
	/** @type {t.VueQuery.Query['messages:published']['Key']} */
	const queryKey = reactive(['messages:published', { user_id: userId }]);

	const queryFn = async () => {
		return messageApi.fetchPublicizedMessagesByUser({
			user_id: fDefined(queryKey[1].user_id)
		});
	};
	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!queryKey[1].user_id)
	});

	const query = useQuery(queryKey, queryFn, options);

	const notReadCount = computed(() => {
		if (!query.data.value) return 0;

		return query.data.value.reduce((acc, cur) => {
			// eslint-disable-next-line no-param-reassign
			acc += cur.messageReadLog ? 0 : 1;
			return acc;
		}, 0);
	});

	return { ...query, queryKey, notReadCount };
};

export const usePublicizedMessage = (/** @type { t.Message['docId'] | undefined} */ messageId) => {
	/** @type {t.VueQuery.Query['message:published']['Key']} */
	const queryKey = reactive(['message:published', { message_id: messageId }]);

	const queryFn = async () => {
		return messageApi.getMessageById({ message_id: fDefined(messageId) });
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!queryKey[1].message_id)
	});

	const query = useQuery(queryKey, queryFn, options);

	return { ...query, queryKey };
};

// ANCHOR MessageReadLog

export const messageReadLogKey = {
	/**
	 * @param {t.User['docId']|undefined} userId
	 * @returns {['messageReadLog', { user_id: userId }]}
	 */
	useMessageReadLogs: userId => ['messageReadLog', { user_id: userId }]
};

/** @param {t.User['docId']|undefined} userId */
export const useMessageReadLogs = userId => {
	const queryKey = reactive(messageReadLogKey.useMessageReadLogs(userId));

	const queryFn = async () => {
		return messageApi.getMessageReadLogsWhere({
			user_id: fDefined(queryKey[1].user_id)
		});
	};

	/** @type {t.VueQuery.DataOptions<typeof queryFn>} */
	const options = reactive({
		enabled: computed(() => !!queryKey[1].user_id)
	});

	const query = useQuery(queryKey, queryFn, options);

	return { ...query, secondKey: queryKey[1] };
};

export const useNotification = (/** @type {any} */ userId) => {
	const queryKey = reactive(['notifications', { user_id: userId }]);

	const queryFn = async () => {
		const result = await notificationApi.getNotificationByUserId(userId);
		return result;
	};
	const query = useQuery(queryKey, queryFn);
	return { ...query, queryKey };
};

/**
 * @template T
 * @param {import('@vue/reactivity').Ref<T[]>} list
 */
export const usePagination = (list, { PER_PAGE = PAGINATE.PER_PAGE }) => {
	const listLength = computed(() => list.value.length);

	const maxPage = computed(() => {
		if (listLength.value % PER_PAGE === 0) return listLength.value / PER_PAGE;
		return (listLength.value - (listLength.value % PER_PAGE)) / PER_PAGE + 1;
	});

	const currentPage = computed(() => {
		const queryPage = router.currentRoute.value.query.page;
		if (!queryPage) return 1;
		const page = Number(queryPage);
		if (!page || page < 1) return 1;
		if (page > maxPage.value) return maxPage.value;
		return page;
	});

	const visibleList = computed(() => {
		return list.value.slice((currentPage.value - 1) * PER_PAGE, currentPage.value * PER_PAGE);
	});
	return {
		list,
		listLength,
		maxPage,
		currentPage,
		visibleList
	};
};
