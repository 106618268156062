export const sendNoti = data => {
	return fetch('https://fcm.googleapis.com/fcm/send', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `key=${process.env.VUE_APP_FB_MESSAGING_API_KEY}`
		},
		body: JSON.stringify(data)
	});
};
