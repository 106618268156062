// @ts-check
/* eslint-disable camelcase */
import { reactive, readonly } from 'vue';

const state = reactive({
	/** @type {t.User & { id: t.User['docId'],email: t.User['mail_address'] }} */
	user: ({}),
	resetForm: false
});

export const globalState = readonly(state);
export const globalActions = {
	/** @param {t.userAuthPage.State['user']} user */
	setUser(user) {
		state.user = user || {};
	},
	/** @param {Pick<t.User,'name'|'nickname'|'icon_img'|'greeting'|'prefecture_id'|'age'>} user */
	updateUserProfile(user) {
		state.user.name = user.name || state.user.name;
		state.user.nickname = user.nickname || state.user.nickname;
		state.user.icon_img = user.icon_img || state.user.icon_img;
		state.user.greeting = user.greeting || state.user.greeting;
		state.user.prefecture_id = user.prefecture_id || state.user.prefecture_id;
		state.user.age = user.age || state.user.age;
	}
};
