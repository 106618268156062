/* eslint-disable camelcase */
import { getStartDate } from '@/services/utils';
import firebase from 'firebase/app';
import 'firebase/firestore';
// @ts-ignore
// @ts-ignore
// @ts-ignore
import { keyBy, sortBy } from 'lodash-es';
import { convertDocument, db, getFieldValue, getNotificationDoc, notificationCol, notificationReadLogCol } from './utils';

// @ts-ignore
// @ts-ignore
// @ts-ignore
const { FieldValue } = firebase.firestore;

export default {
	notificationCol,
	notificationReadLogCol,

	// eslint-disable-next-line consistent-return
	// @ts-ignore
	async createNotification(data) {
		const sender_id = data.sender_id;
		const user_id = data.user_id;
		const target_id = data.target_id;
		const notification_type = data.notification_type;
		return db.runTransaction(async tran => {
			// @ts-ignore
			const notificationDocId = getNotificationDoc({ sender_id, user_id, target_id, notification_type });
			const notification = convertDocument(await tran.get(notificationCol.doc(notificationDocId)));
			if (!notification) {
				tran.set(notificationCol.doc(notificationDocId), {
					target_id: data.target_id,
					target_img: data.target_img,
					user_id: data.user_id,
					sender_id: data.sender_id,
					like_count: data.like_count || 0,
					notification_type: data.notification_type,
					read: false,
					id: notificationDocId,
					delete_flag: data.delete_flag,
					created_at: data.created_at,
					updated_at: data.updated_at
				});
			} else {
				tran.update(notificationCol.doc(notificationDocId), {
					updated_at: getFieldValue().serverTimestamp(),
					read: false,
					like_count: data.like_count || 0
				});
			}
		});
	},
	// @ts-ignore
	async getNotificationByUserId(user_id) {
		const nowStr = getStartDate();
		const data = await notificationCol.where('user_id', '==', user_id).get();
		return sortBy(
			data.docs.map(convertDocument).filter(n => n.updated_at <= nowStr),
			n => -n.updated_at.seconds
		);
	},
	async setReadAllNotifications(user_id) {
		const batch = db.batch();
		const data = await notificationCol.where('user_id', '==', user_id).get();
		data.docs.forEach(noti => {
			const notiRef = notificationCol.doc(noti.id);
			batch.update(notiRef, {
				read: true
			});
		});
		batch.commit();
	}
};
