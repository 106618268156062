import firebase from 'firebase/app';
import 'firebase/firestore';
import { convertDocument } from './utils';

/** @type {firebase.firestore.CollectionReference<t.M_NgWord>} */
const ngWordApi = (firebase.firestore().collection('m_ng_word'));

export default {
	getAll: async () => {
		const result = await ngWordApi.get();
		return result.docs.map(convertDocument);
	},

	async checkNotContainNgWord(word = '') {
		const ngWords = await this.getAll();

		return ngWords.every(w => !word.includes(w.ng_word));
	}
};
