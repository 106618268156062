import firebase from 'firebase/app';
import 'firebase/firestore';
import { keyBy } from 'lodash-es';
import { convertDocument } from './utils';

/** @type {firebase.firestore.CollectionReference<t.C_Prefecture>} */
const prefectureCol = (firebase.firestore().collection('c_prefecture'));

export default {
	getAll: async () => {
		const result = (await prefectureCol.get()).docs.map(convertDocument).sort((a, b) => {
			return a?.display_order - b?.display_order;
		});
		// 3WjaSV7vrysX3NnzA8sJ
		return keyBy(result, p => p.docId);
	}
};
