<template>
	<svg class="fill-current" x="0px" y="0px" viewBox="0 0 490.8 490.8">
		<g>
			<path
				d="M231.696,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82
				l227.115,227.136L216.592,472.461c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262
				c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L231.696,3.128z"
			/>
			<path
				d="M274.363,237.795L39.696,3.128c-4.237-4.093-10.99-3.975-15.083,0.262
				c-3.992,4.134-3.992,10.687,0,14.821l227.115,227.136L24.592,472.461c-4.237,4.093-4.354,10.845-0.262,15.083
				c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667
				C278.511,248.718,278.521,241.966,274.363,237.795z"
			/>
		</g>
		<path
			d="M224.144,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115
			L216.592,18.232c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l234.667,234.667
			c4.164,4.165,4.164,10.917,0,15.083L231.675,487.565C229.676,489.56,226.968,490.68,224.144,490.68z"
		/>
		<path
			d="M32.144,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115
			L24.592,18.232c-4.171-4.171-4.171-10.933,0-15.104s10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083
			L39.696,487.544C37.695,489.551,34.978,490.68,32.144,490.68z"
		/>
	</svg>
</template>
