/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import firebase from 'firebase/app';
import 'firebase/firestore';
import { keyBy } from 'lodash-es';
import postApi from './postApi';
import likeApi from './likeApi';
import { convertDocument, db, favoriteCol, getFavoriteDoc } from './utils';

const { FieldValue } = firebase.firestore;
export default {
	/** @param {Pick<t.Favorite,'user_id'> & PickPartial<t.Favorite,'delete_flag'>} param */
	async getAllByUser({ user_id, delete_flag = 0 }) {
		let [favoriteResult] = await Promise.all([
			favoriteCol
				.where('user_id', '==', user_id)
				.where('delete_flag', '==', delete_flag)
				.where('undisplay_flag', '==', 0)
				.get()
		]);

		return favoriteResult.docs.map(convertDocument);
	},

	getCol() {
		return favoriteCol;
	},
	/** @param {Pick<t.Favorite,'user_id'|'post_id'|'post_img'> & {post_created_at: Date}} param */
	async toggleFavoritePost({ post_id, user_id, post_created_at, post_img }) {
		const favoriteDocId = getFavoriteDoc({ post_id, user_id });

		return db.runTransaction(async tran => {
			const favorite = convertDocument(await tran.get(favoriteCol.doc(favoriteDocId)));
			if (!favorite) {
				tran.set(favoriteCol.doc(favoriteDocId), {
					user_id,
					post_id,
					post_img,
					like_count: 0,
					delete_flag: 0,
					// @ts-ignore
					created_at: FieldValue.serverTimestamp(),
					updated_at: null,
					// @ts-ignore
					post_created_at,
					undisplay_flag: 0
				});
			} else {
				let newDeleteFlag = 0;
				if (typeof favorite?.delete_flag === 'undefined') {
					newDeleteFlag = 0;
				} else {
					newDeleteFlag = favorite?.delete_flag === 1 ? 0 : 1;
				}
				tran.update(favoriteCol.doc(favoriteDocId), {
					user_id,
					post_id,
					post_img,
					delete_flag: newDeleteFlag,
					updated_at: FieldValue.serverTimestamp(),
					undisplay_flag: 0,
					post_created_at
				});
			}
		});
	},
	/** @param {Pick<t.Favorite,'user_id'|'post_id'} */
	async getByPost({ user_id, post_id }, options = { checkDeleteFlag: true }) {
		let docRef = await favoriteCol.doc(getFavoriteDoc({ user_id, post_id })).get();

		if (docRef.exists === false) {
			return null;
		}

		const data = convertDocument(docRef);

		if (options.checkDeleteFlag && data?.delete_flag === 1) {
			return null;
		}
		return data;
	},

	async fetchDataByUser({ user_id }) {
		let [favoriteResult, likeResult] = await Promise.all([this.getAllByUser({ user_id }), likeApi.getAllByUser({ user_id })]);
		likeResult = keyBy(likeResult, l => l.post_id);
		favoriteResult = favoriteResult
			.map(f => {
				f.like = likeResult[f.post_id];
				return f;
			})
			.sort((a, b) => {
				// des
				return -((a.like?.like_count || -Infinity) - (b.like?.like_count || -Infinity));
			});

		return favoriteResult;
	}
};
