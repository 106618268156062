/* eslint-disable camelcase */
import { getStartDate } from '@/services/utils';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { keyBy, sortBy } from 'lodash-es';
import { convertDocument, messageCol, messageReadLogCol, messageMediumCol } from './utils';

const { FieldValue } = firebase.firestore;

export default {
	messageCol,
	messageReadLogCol,
	messageMediumCol,
	/** @param {Pick<t.Message,'sender_name' |'sender_type' | 'message_title' | 'publicize_flag' | 'message'| 'publish_date'>} data */
	insert: async data => {
		return messageCol.add({
			sender_type: data.sender_type || 0,
			message_title: data.message_title,
			sender_name: data.sender_name,
			publish_date: data.publish_date,
			message: data.message,
			publicize_flag: data.publicize_flag || 0,
			delete_flag: 0,
			// @ts-ignore
			created_at: FieldValue.serverTimestamp(),
			updated_at: null
		});
	},
	/** @param {Pick<t.Message,'publicize_flag' | 'delete_flag'>} param */
	async getMessagesWhere({ publicize_flag, delete_flag }) {
		let query;
		const nowStr = getStartDate();
		if (typeof publicize_flag !== 'undefined' && typeof delete_flag !== 'undefined') {
			query = messageCol.where('publicize_flag', '==', publicize_flag).where('delete_flag', '==', delete_flag);
		} else {
			throw Error('Error Query');
		}
		const refs = await query.get();
		return sortBy(
			refs.docs.map(convertDocument).filter(m => m.publish_date <= nowStr),
			m => -m.created_at.seconds
		);
	},

	/** @param {Pick<t.MessageReadLog,'user_id'>} param */
	async getMessageReadLogsWhere({ user_id }) {
		let query;
		if (typeof user_id !== 'undefined') {
			query = messageReadLogCol.where('user_id', '==', user_id);
		} else {
			throw Error('Error Query');
		}

		const refs = await query.get();
		return keyBy(refs.docs.map(convertDocument), m => m.message_id);
	},

	/** @param {Pick<t.MessageMedium,'message_id'>} param */
	async getMessageMediumWhere({ message_id }) {
		let query;
		if (message_id) {
			query = messageMediumCol.where('message_id', '==', message_id);
		} else {
			query = messageMediumCol;
		}
		const refs = await query.get();
		return refs.docs.map(convertDocument);
	},

	/** @param {{ user_id: t.User['docId'] }} param */
	async fetchPublicizedMessagesByUser({ user_id }) {
		const [messages, messageReadLogs] = await Promise.all([this.getMessagesWhere({ publicize_flag: 1, delete_flag: 0 }), this.getMessageReadLogsWhere({ user_id })]);

		return messages.map(m => {
			return {
				...m,
				messageReadLog: messageReadLogs[m.docId]
			};
		});
	},

	/** @param {{ message_id: t.Message['docId']}} param */
	async getMessageById({ message_id }) {
		const [result, messageMediums] = await Promise.all([messageCol.doc(message_id).get(), this.getMessageMediumWhere({ message_id })]);

		return {
			...convertDocument(result),
			medium_type: messageMediums?.[0]?.medium_type,
			messageMediums
		};
	}
};
