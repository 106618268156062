// @ts-check
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import firebase from 'firebase/app';
import 'firebase/firestore';
import userApi from '@/apis/userApi';
import { convertDocument, getFollowerDoc } from './utils';

const { FieldValue } = firebase.firestore;

/** @type {firebase.firestore.CollectionReference<t.Follow>} */
const followCol = (firebase.firestore().collection('d_follow'));
const followApi = {
	/** @param {Pick<t.Follow,'user_id'>} args */
	async getFollowingByUser({ user_id }) {
		// Remove user deleted, locked
		const [data, accountLockUsers] = await Promise.all([
			followCol
				.where('user_id', '==', user_id)
				.where('delete_flag', '==', 0)
				.get(),
			userApi.getAccountLockUsers()
		]);
		return data.docs.map(convertDocument).filter(f => !accountLockUsers[f?.follow_user_id]);
	},

	/** @param {Pick<t.Follow,'user_id'>} args */
	async getFollowerByUser({ user_id }) {
		const [data, accountLockUsers] = await Promise.all([
			followCol
				.where('follow_user_id', '==', user_id)
				.where('delete_flag', '==', 0)
				.get(),
			userApi.getAccountLockUsers()
		]);
		return data.docs.map(convertDocument).filter(f => !accountLockUsers[f.user_id]);
	},
	/**
	 * @param { Pick<t.Follow,'user_id' | 'follow_user_id'> } param
	 */
	async getWhere({ user_id, follow_user_id }) {
		let query;
		if (typeof user_id !== 'undefined' && typeof follow_user_id !== 'undefined') {
			query = followCol.where('user_id', '==', user_id);
		} else {
			throw new Error();
		}
		const refs = await query.get();
		return refs.docs.map(convertDocument);
	},
	/**
	 * @param { Pick<t.Follow,'user_id' | 'follow_user_id'> } param
	 */
	async getFollowRecord({ user_id, follow_user_id }, options = { checkDeleteFlag: true }) {
		const followDocId = getFollowerDoc({ user_id, follow_user_id });

		const docRef = await followCol.doc(followDocId).get();

		if (docRef.exists === false) {
			return null;
		}

		const data = convertDocument(docRef);

		if (options?.checkDeleteFlag && data?.delete_flag === 1) return null;
		return data;
	},

	async getIsFollowing({ user_id, follow_user_id }) {
		const result = await followCol
			.where('user_id', '==', user_id)
			.where('follow_user_id', '==', follow_user_id)
			.where('delete_flag', '==', 0)
			.get();
		return !!result.docs.map(convertDocument)[0];
	},

	/**
	 * @param { Pick<t.Follow,'user_id' | 'follow_user_id'> } param
	 */
	async toggleFollowUser({ user_id, follow_user_id }) {
		const followDocId = getFollowerDoc({ user_id, follow_user_id });
		const followRef = await followCol.doc(followDocId).get();
		const data = followRef.data();
		if (!data) {
			await followCol.doc(followDocId).set({
				user_id,
				follow_user_id,
				delete_flag: 0,
				// @ts-ignore
				created_at: FieldValue.serverTimestamp(),
				undisplay_flag: 0,
				updated_at: null
			});
			return { docId: followDocId };
		}

		await followCol.doc(followDocId).update({
			delete_flag: data.delete_flag === 1 ? 0 : 1,
			// @ts-ignore
			updated_at: FieldValue.serverTimestamp()
		});
		return { docId: followDocId };
	}
};

export default followApi;
