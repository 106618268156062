/* eslint-disable camelcase */
import { REPORT, REPORT_TYPE } from '@/constants';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { groupBy, sortBy } from 'lodash-es';
import { convertDocument, db, postCol, getFieldValue } from './utils';
// @ts-ignore
import { assert } from '../services/utils';
import commentApi from './commentApi';
import mailApi from './mailApi';
import userApi from './userApi';

/** @type {firebase.firestore.CollectionReference<t.Report>} */
const reportCol = (firebase.firestore().collection('d_report'));

/** @type {firebase.firestore.CollectionReference<t.M_Puzzle>} */
// @ts-ignore
const puzzleCol = (firebase.firestore().collection('m_puzzle'));

// @ts-ignore
const getPuzzlePieceCol = puzzle_id => {
	return firebase
		.firestore()
		.collection('m_puzzle')
		.doc(puzzle_id)
		.collection('m_puzzle_piece');
};

/** @type {firebase.firestore.CollectionReference<t.Comment>} */
export const commentCol = (firebase.firestore().collection('d_comment'));
export default {
	col: reportCol,

	/** @param {Pick<t.Report,'user_id'>} param */
	async getByUserId({ user_id }) {
		const refs = await reportCol.where('user_id', '==', user_id).get();

		const data = refs.docs.map(convertDocument);
		/** @type {{ [key in t.Report['report_type']]: Record<string,t.Report> }} */
		const map = ({ 1: {}, 2: {} });

		// eslint-disable-next-line no-restricted-syntax
		for (const report of data) {
			map[report.report_type][report.target_id] = report;
		}
		return map;
	},
	/** @param {t.Report['target_id']} post_id */
	async getByPostId(post_id) {
		const refs = await reportCol
			.where('target_id', '==', post_id)
			.where('report_type', '==', REPORT_TYPE.POST)
			.where('delete_flag', '==', 0)
			.get();

		return refs.docs.map(convertDocument);
	},
	async getCommentsReports() {
		const refs = await reportCol
			.where('report_type', '==', REPORT_TYPE.COMMENT)
			.where('delete_flag', '==', 0)
			.get();
		return groupBy(refs.docs.map(convertDocument), c => c.target_id);
	},

	/** @param {Pick<t.Report,'target_id'|'report_type'>} */
	async getByTarget({ target_id, report_type }) {
		const [reports, deletedUsers] = await Promise.all([
			(async () => {
				const refs = await reportCol
					.where('delete_flag', '==', 0)
					.where('target_id', '==', target_id)
					.where('report_type', '==', report_type)
					.get();
				return sortBy(refs.docs.map(convertDocument), r => r.created_at.seconds);
			})(),
			userApi.getDeletedAccountUsers()
		]);

		return reports.filter(r => !deletedUsers[r.user_id]);
	},
	/**
	 * @param {t.Param.CreatePostParam} data
	 */
	async createReport(data) {
		if (data.report_type === 1) {
			const targetReports = await this.getByTarget({ report_type: data.report_type, target_id: data.post.docId });
			// post.undisplay_flag_by_admin = 0,1,null
			const updateCountReport = (data.post?.count_reports || 0) + 1;
			const undisplayFlagByAdmin = data.post.undisplay_flag_by_admin ?? null;
			const checkMaxReport = updateCountReport >= REPORT.MAX_REPORT ? 1 : 0;
			const updatingUndisplayFlag = undisplayFlagByAdmin ?? checkMaxReport;

			const batch = db.batch();
			// @ts-ignore
			batch.set(reportCol.doc(), {
				user_id: data.user_id,
				report_type: data.report_type,
				target_id: data.post.docId,
				report_content: data.report_content,
				read_flag: 0,
				// @ts-ignore
				readed_at: new Date(3000, 1, 1),
				response_flag: 0,
				delete_flag: 0,
				publicize_flag: 0,
				post_user_id: data.post_user_id,
				target_undisplay_flag: updatingUndisplayFlag,
				// @ts-ignore
				created_at: firebase.firestore.FieldValue.serverTimestamp(),
				puzzle_id: data.puzzle_id,
				puzzle_index: data.puzzle_index,
				piece_id: data.piece_id,
				undisplay_flag: 0
			});

			batch.update(postCol.doc(data.post.docId), {
				count_reports: updateCountReport,
				undisplay_flag: updatingUndisplayFlag,
				updated_at: getFieldValue().serverTimestamp()
			});
			batch.update(getPuzzlePieceCol(data.post.puzzle_id).doc(data.post.piece_id), {
				undisplay_flag: updatingUndisplayFlag,
				updated_at: getFieldValue().serverTimestamp()
			});
			targetReports.forEach(r => {
				batch.update(reportCol.doc(r.docId), {
					target_undisplay_flag: updatingUndisplayFlag,
					updated_at: getFieldValue().serverTimestamp()
				});
			});

			await batch.commit();
			if (checkMaxReport) {
				mailApi.send({
					// @ts-ignore
					to: data.post.mail_address,
					// @ts-ignore
					message: {
						subject: `「あすたーとぴーす」より通報のお知らせです`,
						html: `<div>「あすたーとぴーす」にアップされた絵が不適切な絵と判断され、</div>
							<div>3回通報を受けた為一時的に非公開となっております。</div>
							<div>ご本人様のマイページより非公開中の絵をご確認頂き、</div>
							<div>削除して頂くようお願い致します。</div>

							<p>内容にご質問が有る場合は、ホームページよりメールをお送りください。</p>

							<p>今後とも「あすたーとぴーす」をよろしくお願い致します。</p>

							<div>一般社団法人A Start Piece Lab.</div>
							<div>https://astartpiece.com/</div>`
					}
				});
			}
			return;
		}
		if (data.report_type === 2) {
			const targetReports = await this.getByTarget({
				report_type: data.report_type,
				target_id: data.comment.docId
			});
			// @ts-ignore
			const updateCountReport = (data.comment?.count_reports || 0) + 1;
			const undisplayFlagByAdmin = data.comment.undisplay_flag_by_admin ?? null;
			const checkMaxReport = updateCountReport >= REPORT.MAX_REPORT ? 1 : 0;
			const updatingUndisplayFlag = undisplayFlagByAdmin ?? checkMaxReport;

			const batch = db.batch();

			batch.set(reportCol.doc(), {
				user_id: data.user_id,
				report_type: data.report_type,
				target_id: data.comment.docId,
				report_content: data.report_content,
				read_flag: 0,
				// @ts-ignore
				readed_at: new Date(3000, 1, 1),
				post_user_id: data.post_user_id,
				response_flag: 0,
				delete_flag: 0,
				publicize_flag: 0,
				target_undisplay_flag: updatingUndisplayFlag,
				// @ts-ignore
				created_at: firebase.firestore.FieldValue.serverTimestamp(),
				puzzle_id: data.puzzle_id,
				puzzle_index: data.puzzle_index,
				piece_id: data.piece_id,
				undisplay_flag: 0
			});

			batch.update(commentApi.getCol().doc(data.comment.docId), {
				count_reports: updateCountReport,
				undisplay_flag: updatingUndisplayFlag,
				updated_at: getFieldValue().serverTimestamp()
			});
			targetReports.forEach(r => {
				batch.update(reportCol.doc(r.docId), {
					target_undisplay_flag: updatingUndisplayFlag,
					updated_at: getFieldValue().serverTimestamp()
				});
			});

			await batch.commit();
			return;
		}
		throw new Error('Error');
	}
};
