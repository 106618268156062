<template>
	<svg class="fill-current" x="0px" y="0px" viewBox="0 0 490.787 490.787">
		<g>
			<path
				d="M47.087,245.454L274.223,18.339c4.237-4.093,4.355-10.845,0.262-15.083
				c-4.093-4.237-10.845-4.355-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262L24.474,237.923c-4.164,4.165-4.164,10.917,0,15.083
				L259.14,487.672c4.237,4.093,10.99,3.975,15.083-0.262c3.993-4.134,3.993-10.687,0-14.821L47.087,245.454z"
			/>
			<path
				d="M466.223,3.235c-4.165-4.164-10.917-4.164-15.083,0L216.474,237.902
				c-4.164,4.165-4.164,10.917,0,15.083L451.14,487.651c4.237,4.093,10.99,3.976,15.083-0.261c3.993-4.134,3.993-10.688,0-14.821
				L239.087,245.454L466.223,18.339c4.171-4.16,4.179-10.914,0.019-15.085C466.236,3.248,466.229,3.241,466.223,3.235z"
			/>
		</g>
		<path
			d="M266.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L24.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L259.119,3.256
			c4.093-4.237,10.845-4.355,15.083-0.262c4.237,4.093,4.355,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262
			L47.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C272.236,489.664,269.511,490.792,266.671,490.787z"
		/>
		<path
			d="M458.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L216.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L451.119,3.256
			c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262
			L239.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C464.236,489.664,461.511,490.792,458.671,490.787z"
		/>
	</svg>
</template>
