/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import firebase from 'firebase/app';
import 'firebase/firestore';
import { sortBy } from 'lodash-es';
import { convertDocument, db, getFieldValue, puzzleCol } from './utils';
import likeApi from './likeApi';
import { commentCol } from './reportApi';

const { FieldValue } = firebase.firestore;
/** @type {firebase.firestore.CollectionReference<t.Post>} */
const postCol = (firebase.firestore().collection('d_post'));

export default {
	/** @param {Pick<t.Post,'user_id'> & Partial<Pick<t.Post,'undisplay_flag'| 'delete_flag'>> } param */
	getAllByUser: async ({ user_id, undisplay_flag = 0, delete_flag = 0 }) => {
		const result = await postCol
			.where('user_id', '==', user_id)
			.where('delete_flag', '==', delete_flag)
			.get();

		return sortBy(result.docs.map(convertDocument), i => -i.created_at?.seconds || 0);
	},

	getByPieceId: async ({ user_id, puzzle_piece_id }) => {
		const result = await postCol
			.where('user_id', '==', user_id)
			.where('puzzle_piece_id', '==', puzzle_piece_id)
			.where('delete_flag', '==', 0)
			.get();
		return result.docs.map(convertDocument);
	},

	getAll: async () => {
		const result = await postCol
			.where('delete_flag', '==', 0)
			.where('undisplay_flag', '==', 0)
			.get();
		return result.docs.map(convertDocument);
	},

	/**
	 * @param {t.Post['docId']} docId
	 */
	async deleteByDocId(docId) {
		try {
			const postData = convertDocument(await postCol.doc(docId).get());
			const batch = db.batch();
			if (postData.delete_flag === 1) {
				return;
			}
			const puzzlePieceRef = await puzzleCol
				.doc(postData.puzzle_id)
				.collection('m_puzzle_piece')
				.doc(postData.piece_id);

			batch.update(postCol.doc(docId), {
				delete_flag: 1,
				delete_by_delete_user: false,
				updated_at: FieldValue.serverTimestamp()
			});

			batch.update(puzzleCol.doc(postData.puzzle_id), {
				posted_count: getFieldValue().increment(-1),
				updated_at: getFieldValue().serverTimestamp()
			});

			batch.update(puzzlePieceRef, {
				post_flag: false,
				post_id: firebase.firestore.FieldValue.delete(),
				post_thumbnail: firebase.firestore.FieldValue.delete(),
				post_user_type: firebase.firestore.FieldValue.delete(),
				post_delete_flag: firebase.firestore.FieldValue.delete(),
				undisplay_flag: firebase.firestore.FieldValue.delete(),
				search_text: firebase.firestore.FieldValue.delete(),
				updated_at: firebase.firestore.FieldValue.serverTimestamp()
			});
			await batch.commit();
		} catch (error) {
			console.log(error);
		}
	},

	getCol() {
		return postCol;
	},

	/**
	 * @param {t.Post} post
	 */
	async getLikeCountInternalOrUpdate(post) {
		if (typeof post.like_count !== 'undefined') return post.like_count;
		// NOTE update post.like_count
		console.log(`update post.like_count : `, post.like_count);
		const likeCount = await likeApi.countLikeToPost(post.docId);
		await postCol.doc(post.docId).update(
			/** @type {Partial<t.Post>} */ ({
				like_count: likeCount,
				updated_at: getFieldValue().serverTimestamp()
			})
		);
		return likeCount;
	},
	/**
	 * @param {t.Post['docId']} docId
	 */
	async getByDocId(docId) {
		const result = await postCol.doc(docId).get();
		if (!result.exists) return null;
		const data = convertDocument(result);

		if (data?.delete_flag === 1) return null;

		return {
			...data,
			like_count: await this.getLikeCountInternalOrUpdate(data)
		};
	}
};
